
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc } from '@/models/jsonapi'
  import { Suggestion } from "@/models/suggestions";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"

  export default defineComponent({
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed(() => root.$store.state.suggestions.pagination.totalItems)

      const getItems = async(ctx: BvTableCtxObject) => {
        const queryParams = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': '-createdAt',
        }
        await root.$store.dispatch('suggestions/load', queryParams)
        return root.$store.state.suggestions.data 
      }

      const columns = ref([
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.stage",
          title: "Status",
          sortable: true,
        },
      ])

      return {
        getItems,
        totalItems,
        columns
      }
    }
  })
